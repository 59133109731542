<template>
  <div class="faq">
    <div>
      <div
        class="faq-header"
        :class="{ active: showList.indexOf(0) != -1 }"
        @click="toggleFaq(0)"
      >
        {{ $t("driver_faq1_title") }}
      </div>
      <div class="faq-content">
        <p v-html="$t('driver_faq1_text')"></p>
      </div>
    </div>

    <div>
      <div
        class="faq-header"
        :class="{ active: showList.indexOf(1) != -1 }"
        @click="toggleFaq(1)"
      >
        {{ $t("driver_faq2_title") }}
      </div>
      <div class="faq-content">
        <p v-html="$t('driver_faq2_text')"></p>
      </div>
    </div>

    <div>
      <div
        class="faq-header"
        :class="{ active: showList.indexOf(2) != -1 }"
        @click="toggleFaq(2)"
      >
        {{ $t("driver_faq3_title") }}
      </div>
      <div class="faq-content">
        <p v-html="$t('driver_faq3_text')"></p>
      </div>
    </div>

    <div>
      <div
        class="faq-header"
        :class="{ active: showList.indexOf(3) != -1 }"
        @click="toggleFaq(3)"
      >
        {{ $t("driver_faq4_title") }}
      </div>
      <div class="faq-content">
        <p v-html="$t('driver_faq4_text')"></p>
      </div>
    </div>

    <div>
      <div
        class="faq-header"
        :class="{ active: showList.indexOf(4) != -1 }"
        @click="toggleFaq(4)"
      >
        {{ $t("driver_faq5_title") }}
      </div>
      <div class="faq-content">
        <p v-html="$t('driver_faq5_text')"></p>
      </div>
    </div>

    <FaqItem
      :showList="showList"
      @openFaq="toggleFaq"
      :data="{
        id: 6,
        title: $t('driver_faq6_title'),
        content: {
          items: [{ text: $t('driver_faq6_text'), img: 'steps/driver/4.jpg' }],
        },
      }"
    ></FaqItem>

    <div>
      <div
        class="faq-header"
        :class="{ active: showList.indexOf(5) != -1 }"
        @click="toggleFaq(5)"
      >
        {{ $t("driver_faq7_title") }}
      </div>
      <div class="faq-content">
        <p v-html="$t('driver_faq7_text')"></p>
      </div>
    </div>
  </div>
</template>

<script>
import FaqItem from "./FaqItem.vue";
export default {
  components: {
    FaqItem,
  },
  data() {
    return {
      active: 0,
      showList: [],
    };
  },
  methods: {
    toggleFaq(id) {
      let index = this.showList.indexOf(id);
      if (index != -1) {
        this.showList.splice(index, 1);
      } else {
        this.showList.push(id);
      }
    },
  },
};
</script>
