<template>
    <div>
        <div ref="faq_header"
             class="faq-header"
             :class="{active: showList.indexOf(data.id) != -1}"
             @click="$emit('openFaq', data.id)"
             v-html="data.title">
        </div>
        <div class="faq-content">
            <div v-if="data.content.text"
                 v-html="data.content.text"></div>
            <div class="faq-table-wrapper" v-if="data.content.items" :class="{_wide: data.content.items.length > 2}">

                <div class="mobile-pagi" v-if="$isMobile() && data.content.items.length > 1">
                    <div>
                        <div v-for="(item, key) in data.content.items" :key="key" :class="{active: key == active}"></div>
                    </div>
                </div>

                <swiper ref="mySwiperr"
                        :class="{'swiper-no-swiping': data.content.items.length < 4 && !$isMobile()}"
                        :options="swiperOptions"
                        @slideChange="onSlideChange"
                >

                    <swiper-slide :class="{solo: data.content.items.length == 1}" v-for="(item, key) in data.content.items" :key="key">
                        <div class="text">
                            <p v-html="item.text"></p>
                        </div>
                        <div class="phone-image">
                            <img class="phone-border" src="@/assets/images/phone-border.png"/>
                            <img class="phone-content" :src="require('@/assets/images/' + item.img)"/>
                        </div>
                    </swiper-slide>

                    <div class="swiper-scrollbar" slot="scrollbar"></div>
                </swiper>

                <div class="swipe-prev" @click="rollPrev()" :class="{_show: active > 0}"></div>
                <div class="swipe-next" @click="rollNext()" :class="{_show: active < data.content.items.length - swiperOptions.slidesPerView}"></div>
            </div>
        </div>
    </div>
</template>

<script>
    import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
    import 'swiper/swiper-bundle.css'

    export default {
        props: ['data', 'showList', 'no_text_items'],
        components: {
            Swiper,
            SwiperSlide
        },
        data () {
            return {
                //no_text_table: false,
                active: 0,
                rollCount: 2,
                swiperOptions: {
                    grabCursor: true,
                    slidesPerView: 3,
                    spaceBetween: 0,
                    /*mousewheel: {
                        releaseOnEdges: true
                    },*/
                    scrollbar: {
                        el: '.swiper-scrollbar',
                        draggable: true
                    },
                }
            }
        },
        computed: {
            swiper() {
                return this.$refs.mySwiperr.$swiper
            }
        },
        methods: {
            rollNext () {
                let to_id = this.active + this.rollCount
                if (to_id > this.data.content.items.length - this.swiperOptions.slidesPerView) to_id = this.data.content.items.length - this.swiperOptions.slidesPerView
                this.slideTo(to_id)
            },
            rollPrev () {
                let to_id = this.active - this.rollCount
                if (to_id < 0) to_id = 0
                this.slideTo(to_id)
            },
            slideTo (id) {
                this.swiper.slideTo(id)
            },
            onSlideChange() {
                this.active = this.swiper.activeIndex
            }
        },
        created () {
            if (this.$isMobile()) {
                this.rollCount = 1
                this.swiperOptions.slidesPerView = 1
                //this.swiperOptions.autoHeight = true
                //this.swiperOptions.mousewheel = false
            } else if (this.data.content.items && this.data.content.items.length < 4) {
                //this.swiperOptions.mousewheel = false
                if (this.data.content.items.length < 3) {
                    this.swiperOptions.slidesPerView = this.data.content.items.length
                }
            }
        }
    }
</script>