<template>
    <div class="big-slider">

        <div class="pagination">
            <div class="pagination-item" v-for="(item, key) in data" :key="key" :class="{'active': active == key}" @click="slideTo(key)">
                <div>{{ key + 1 }}</div>
                <span>{{ item.preview }}</span>
            </div>

        </div>

        <swiper class="main-slider" :options="options" ref="mySwiper" @slideChange="onSlideChange">
            <swiper-slide v-for="(item, key) in data" :key="key">
                <div class="inline mob-col">
                    <div class="image-wrapper" :class="{_svg: isSvg(item.img)}">
                        <img :src="require('@/assets/images/' + item.img)"/>
                    </div>
                    <div class="text">
                        <p class="text-size1" v-html="item.text"></p>
                    </div>
                </div>
            </swiper-slide>
        </swiper>
    </div>
</template>

<script>
    import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
    import 'swiper/swiper-bundle.css'

    export default {
        props: ['data'],
        components: {
            Swiper,
            SwiperSlide
        },
        computed: {
            swiper() {
                return this.$refs.mySwiper.$swiper
            }
        },
        data () {
            return {
                active: 0,
                options: {
                    grabCursor: true,
                    autoHeight: false,
                    autoplay: {
                        delay: 5000,
                        stopOnLastSlide: false,
                        disableOnInteraction: false
                    }
                }
            }
        },
        methods: {
            isSvg (img) {
                let dotIndex = img.lastIndexOf('.');
                let ext = img.substring(dotIndex);
                if (ext == '.svg') return true
                return false
            },
            slideTo (id) {
                this.swiper.slideTo(id)
            },
            onSlideChange() {
                this.active = this.swiper.activeIndex
            }
        }
    };
</script>