<template>
  <section class="previews">
    <div class="previews-wrapper">
      <div class="phone-image">
        <img class="phone-border" src="@/assets/images/phone-border-s.png" />
        <img
          class="phone-content"
          src="~@/assets/images/previews/upscreen_0.jpg"
        />
        <img
          class="phone-content"
          src="~@/assets/images/previews/upscreen_1.jpg"
        />
      </div>
      <div class="phone-image">
        <img class="phone-border" src="@/assets/images/phone-border-s.png" />
        <img
          class="phone-content"
          src="~@/assets/images/previews/upscreen_0.jpg"
        />
        <img
          class="phone-content"
          src="~@/assets/images/previews/upscreen_2.jpg"
        />
      </div>
      <div class="phone-image">
        <img class="phone-border" src="@/assets/images/phone-border-s.png" />
        <img
          class="phone-content"
          src="~@/assets/images/previews/upscreen_0.jpg"
        />
        <img
          class="phone-content"
          src="~@/assets/images/previews/upscreen_3.jpg"
        />
      </div>
      <div class="phone-image">
        <img class="phone-border" src="@/assets/images/phone-border-s.png" />
        <img
          class="phone-content"
          src="~@/assets/images/previews/upscreen_0.jpg"
        />
        <img
          class="phone-content"
          src="~@/assets/images/previews/upscreen_4.jpg"
        />
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: ["showAnimation"],
};
</script>
