<template>
  <div class="steps">
    <h2 class="subtitle">{{ $t("common_guides_title") }}</h2>
    <swiper ref="mySwiper" :options="options" @slideChange="onSlideChange">
      <swiper-slide slide="1">
        <div class="wrapper">
          <div class="text">
            <p v-html="$t('driver_guide_1')"></p>
          </div>
          <img class="qr-mobile-step" src="~@/assets/images/qr.svg" />
        </div>
      </swiper-slide>
      <swiper-slide slide="2">
        <div class="wrapper">
          <div class="text">
            <p v-html="$t('driver_guide_2')"></p>
          </div>
          <div class="phone-image gradient">
            <img class="phone-border" src="@/assets/images/phone-border.png" />
            <img
              class="phone-content"
              src="~@/assets/images/steps/driver/2.jpg"
            />
          </div>
        </div>
      </swiper-slide>
      <swiper-slide slide="3">
        <div class="wrapper">
          <div class="text">
            <p v-html="$t('driver_guide_3')"></p>
          </div>
          <div class="phone-image gradient">
            <img class="phone-border" src="@/assets/images/phone-border.png" />
            <img
              class="phone-content"
              src="~@/assets/images/steps/driver/3.jpg"
            />
          </div>
        </div>
      </swiper-slide>

      <swiper-slide slide="4">
        <div class="wrapper">
          <div class="text">
            <p v-html="$t('driver_guide_4')"></p>
          </div>
          <div class="phone-image gradient">
            <img class="phone-border" src="@/assets/images/phone-border.png" />
            <img
              class="phone-content"
              src="~@/assets/images/steps/driver/4.jpg"
            />
          </div>
        </div>
      </swiper-slide>
      <swiper-slide slide="5">
        <div class="wrapper">
          <div class="text">
            <p v-html="$t('driver_guide_5')"></p>
          </div>
          <img class="qr-page-step" src="~@/assets/images/steps/driver/1.png" />
        </div>
      </swiper-slide>
    </swiper>

    <div
      class="swipe-prev"
      @click="rollPrev()"
      :class="{ _show: active > 0 }"
    ></div>
    <div
      class="swipe-next"
      @click="rollNext()"
      :class="{ _show: active < 4 }"
    ></div>

    <div class="swiper-pagination">
      <div
        class="swiper-pagination-bullet"
        :class="{ 'swiper-pagination-bullet-active': active == 0 }"
        @click="slideTo(0)"
      ></div>
      <div
        class="swiper-pagination-bullet"
        :class="{ 'swiper-pagination-bullet-active': active == 1 }"
        @click="slideTo(1)"
      ></div>
      <div
        class="swiper-pagination-bullet"
        :class="{ 'swiper-pagination-bullet-active': active == 2 }"
        @click="slideTo(2)"
      ></div>
      <div
        class="swiper-pagination-bullet"
        :class="{ 'swiper-pagination-bullet-active': active == 3 }"
        @click="slideTo(3)"
      ></div>
      <div
        class="swiper-pagination-bullet"
        :class="{ 'swiper-pagination-bullet-active': active == 4 }"
        @click="slideTo(4)"
      ></div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/swiper-bundle.css";

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
  },
  data() {
    return {
      active: 0,
      options: {
        autoplay: {
          delay: 115000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
      },
    };
  },
  methods: {
    rollNext() {
      let to_id = this.active + 1;
      if (to_id > 4) to_id = 5;
      this.slideTo(to_id);
    },
    rollPrev() {
      let to_id = this.active - 1;
      if (to_id < 0) to_id = 0;
      this.slideTo(to_id);
    },
    slideTo(id) {
      this.swiper.slideTo(id);
    },
    onSlideChange() {
      this.active = this.swiper.activeIndex;
    },
  },
};
</script>
