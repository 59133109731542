<template>
  <section id="header" :class="{_top: scrollDir == 'up'}">
    <div class="wrapper inline mob-col" valign="center" halign="between">
      <img :class="{hide: headerButtons}" class="logo" src="~@/assets/images/logo.svg"/>

      <div>
        <div :class="{hide: $isMobile() && !headerButtons}" class="nav inline" valign="center">
          <router-link :to="{ path: '/drivers', hash: '#info' }" class="link" :class="{active: $route.name == 'drivers'}" v-html="$t('common_link_driver')"></router-link>
          <router-link :to="{ path: '/owners', hash: '#info' }" class="link" :class="{active: $route.name == 'owners' || $route.name == 'form'}" v-html="$t('common_link_owner')"></router-link>
        </div>
        <div class="inline lang">
          <span :class="{active: $i18n.locale == lang}" class="link" v-for="(lang, i) in langs" :key="`Lang${i}`" @click="selectLang(lang)">{{ lang }}</span>
        </div>
      </div>

    </div>
  </section>
</template>

<script>
export default {
    data () {
        return {
            langs: ['es', 'en'],
            headerButtons: false,
            offsetTop: 0,
            scrollDir: 'down'
        }
    },
    computed: {
        mobileHeader () {
            //if (this.$isMobile()) return true
            return window.pageYOffset
        }
    },
    methods: {
        selectLang (lang) {
          this.$i18n.locale = lang
        },
        handleScroll () {
            if (this.$isMobile()) {
                let oldOffset = this.offsetTop
                this.offsetTop = window.pageYOffset
                if (oldOffset < this.offsetTop) { //scrolldown
                    this.scrollDir = 'down'
                } else { //scrollup
                    this.scrollDir = 'up'
                }


                if (this.offsetTop > 0) {
                    this.headerButtons = true
                } else {
                    this.headerButtons = false
                }
            }
        }
    },
    created () {
        window.addEventListener('scroll', this.handleScroll);
    }
}
</script>

