<template>
  <div :class="{mobile: $isMobile()}">
    <Header></Header>
    <Slider v-if="!isOwnersPage()"></Slider>
    <DownloadLine v-if="!isOwnersPage()"></DownloadLine>
    <Previews v-if="!$isMobile() && !isOwnersPage()" :showAnimation="showAnimation.previews"
              v-observe-visibility="{ callback: isViewableNow, once: true }"></Previews>

    <Tabs v-if="!isOwnersPage()"></Tabs>

    <router-view/>

    <Footer @showPopup="openPopup"></Footer>

    <Counters></Counters>
    <Popup @closePopup="closePopup()" :showPopup="showPopup"></Popup>
  </div>
</template>

<script>
    import Header from './components/Header.vue'
    import Slider from './components/Slider.vue'
    import DownloadLine from './components/DownloadLine.vue'
    import Previews from './components/Previews.vue'
    import Tabs from './components/Tabs.vue'
    import Footer from './components/Footer.vue'

    import Counters from './components/Counters.vue'
    import Popup from './components/Popup.vue'

    export default {
        name: 'App',
        components: {
            Header,
            Slider,
            DownloadLine,
            Previews,
            Tabs,
            Footer,
            Counters,
            Popup
        },
        data () {
            return {
                showPopup: false,
                showAnimation: {
                    previews: false
                },
            }
        },
        methods: {
            openPopup (data) {
              this.showPopup = data
              if (this.$isMobile()) {
                  document.body.style.overflow = 'hidden'
              }
            },
            isViewableNow() {
                //this.showAnimation[type] = true;
            },
            isOwnersPage() {
              return location.href.indexOf('/owners') != -1
            },
            closePopup () {
                this.showPopup = false
                if (this.$isMobile()) {
                    document.body.style.overflow = 'auto'
                }
                if (location.href.indexOf('/privacy') != -1 || location.href.indexOf('/tyc_es') != -1 || location.href.indexOf('/tyc_en') != -1) {
                    this.$router.push('/drivers')
                }
            }
        },
        mounted () {
            if (location.href.indexOf('/privacy') != -1) {
                this.openPopup({type:'privacy'})
            } else if (location.href.indexOf('/tyc_es') != -1) {
              this.openPopup({lang:'es', type:'terminos'})
            } else if (location.href.indexOf('/tyc_en') != -1) {
              this.openPopup({lang:'en', type:'terminos'})
            }
        }
    }
</script>

<style lang="scss">
  @import url('https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;700&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;700&display=swap');
  @import './assets/styles/reset.css';
  @import './assets/styles/vars.scss';
  @import './assets/styles/header.scss';
  @import './assets/styles/layout.scss';
  @import './assets/styles/slider.scss';
  @import './assets/styles/previews.scss';
  @import './assets/styles/tabs.scss';
  @import './assets/styles/for_drivers.scss';
  @import './assets/styles/for_parkingmans.scss';

  @import './assets/styles/howto.scss';
  @import './assets/styles/digitalSlider.scss';
  @import './assets/styles/faq.scss';
  @import './assets/styles/forms.scss';
  @import './assets/styles/footer.scss';
  @import './assets/styles/questionary.scss';

  @import './assets/styles/popup.scss';

  @import './assets/styles/big-slider.scss';

  @import './assets/styles/adaptive.css';

  $animationDuration: 0.5s; // specify animation duration. Default value: 1s
  @import "./../node_modules/vue2-animate/src/sass/vue2-animate.scss";
</style>
