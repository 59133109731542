<template>
    <div class="youtube" ref="yt" :style="styles">
        <youtube :video-id="video_id"></youtube >
    </div>
</template>

<script>

    export default {
        props: ['video_id'],
        data () {
          return {
              styles: {
                  height: '1px'
              }
          }
        },
        methods: {
            setHeight () {
                let height = this.$refs.yt.clientWidth / 1.7857
                this.styles.height = height + 'px'
            }
        },
        mounted () {
            this.setHeight()
            window.addEventListener("resize", this.setHeight);
        }
    }
</script>

<style lang="scss">
    .youtube {
        & > div {
            border-radius:0.625rem;
            overflow:hidden;
            width:100%;
            height:100%;
            iframe {
                width:100% !important;
                height:100% !important;
            }
        }
    }
    .mobile {
        .youtube {
            margin-left: -20px;
            width: calc(100% + 40px) !important;
            & > div {
                border-radius:0;
            }
        }
    }
</style>